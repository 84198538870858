import React, { useCallback, useMemo } from 'react'
import { Dropdown, Input } from '@mondra/ui-components'
import find from 'lodash/fp/find'
import FormRow, { FormLabel } from 'components/row/FormRow'
import { COMPANY_SIZE_OPTIONS } from 'constants/options'
import { useCompany } from 'hooks/useCompany'
import { SectionTitle } from 'components/SectionTitle'
import { COMPANY_NUMBER_MAX_LENGTH } from '../../constants'
import { CompanyAddress } from './CompanyAddress'

export function CompanyInfoForm() {
  const { company, updateCompany } = useCompany()
  const handleLeEntityChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target
      updateCompany({ [name]: value })
    },
    [updateCompany]
  )

  const handleSizeChange = useCallback(
    (selected?: { value: string }) => {
      updateCompany({ companySize: selected?.value })
    },
    [updateCompany]
  )

  const selectedSize = useMemo(() => {
    return find(c => c.value === company?.companySize, COMPANY_SIZE_OPTIONS)
  }, [company?.companySize])

  return (
    <div className="flex flex-1 flex-col">
      <SectionTitle
        title="About your company"
        description="To create your Mondra account please complete all of the following required information:"
      />
      <div className="mt-6 flex flex-col gap-y-4">
        <FormRow className="flex-row flex-wrap gap-x-4 gap-y-4">
          <div className="flex-grow basis-0">
            <FormLabel>Registered company name</FormLabel>
            <Input
              required
              name="name"
              type="text"
              onChange={handleLeEntityChange}
              value={company?.name || ''}
            />
          </div>
          <div className="flex-grow basis-0">
            <FormLabel>Registration number</FormLabel>
            <Input
              required
              maxLength={COMPANY_NUMBER_MAX_LENGTH}
              name="registrationNumber"
              type="number"
              onChange={handleLeEntityChange}
              value={company?.registrationNumber || ''}
            />
          </div>
        </FormRow>
        <FormRow className="flex-row flex-wrap gap-x-4 gap-y-4">
          <div className="flex-grow basis-0">
            <FormLabel>Company size</FormLabel>
            <Dropdown
              selected={selectedSize}
              options={COMPANY_SIZE_OPTIONS}
              placeholder="Select company size"
              onChange={handleSizeChange}
            />
          </div>
        </FormRow>
        <CompanyAddress />
      </div>
    </div>
  )
}
