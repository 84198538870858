import React, { useMemo } from 'react'
import {
  Button,
  NavStepperCard,
  NavStepperContent,
  NavStepperFooter,
  useNavStepper,
} from '@mondra/ui-components'
import isEmpty from 'lodash/fp/isEmpty'
import { useInviteData } from 'hooks/useInviteData'
import { PersonalProfile } from './PersonalProfile'

export function AboutInformation() {
  const { contact } = useInviteData()
  const { nextStep, previousStep } = useNavStepper()

  const isNextDisabled = useMemo(() => {
    const { firstName, lastName, position } = contact || {}

    return isEmpty(firstName) || isEmpty(lastName) || isEmpty(position)
  }, [contact])

  return (
    <NavStepperCard className="w-full max-w-[1400px]">
      <NavStepperContent className="mx-auto flex h-10 max-w-[680px] flex-col px-4 py-10">
        <form
          className="rounded-md p-6 bg-secondary"
          autoComplete="off"
          id="company-contact-form"
          onSubmit={nextStep}
        >
          <PersonalProfile />
        </form>
      </NavStepperContent>
      <NavStepperFooter>
        <Button iconType="arrowLeft" variant="secondary" onClick={previousStep}>
          Previous step
        </Button>
        <Button
          form="company-contact-form"
          type="submit"
          variant="primary"
          onClick={nextStep}
          disabled={isNextDisabled}
          rightIconType="arrowRight"
        >
          Next step
        </Button>
      </NavStepperFooter>
    </NavStepperCard>
  )
}
