import isEmpty from 'lodash/fp/isEmpty'
import { useAuth0 } from '@auth0/auth0-react'
import { useToastNotification } from '@mondra/ui-components'
import { API_URLS } from 'constants/apiUrls'
import { useUpdateApi } from 'hooks/useUpdateApi'
import { TOAST_POSITION } from 'constants/'
import { parseApiError } from 'utils'
import { IApiError } from 'types'

interface IuseZohoAsapUserTokenProps {
  onUpdate: (token: string) => void
}

export function useZohoAsapUserToken({ onUpdate }: IuseZohoAsapUserTokenProps) {
  const { showError } = useToastNotification(TOAST_POSITION)
  const { isAuthenticated } = useAuth0()
  const apiUrl = isAuthenticated ? API_URLS.ZOHO_ASAP_USER_TOKEN : null

  const onError = (error: IApiError) => {
    showError(
      {
        ...parseApiError(error),
      },
      { duration: 5000, id: error.traceId! }
    )
    onUpdate('')
  }

  const { update: fetchZohoUserToken, saving: isMutating } = useUpdateApi({
    onError,
    onUpdate,
    url: API_URLS.ZOHO_ASAP_USER_TOKEN,
  })

  return {
    fetchZohoUserToken,
    isLoading: isMutating || isEmpty(apiUrl),
  }
}
