/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from 'react'
import { METHOD_POST } from '../constants'
import { useToken } from './useToken'

interface IUseUpdateApiProps {
  companyId?: string
  contentType?: boolean
  method?: string
  onError?: (error: any) => void
  onUpdate?: (response: any) => void
  url: string | null
}

interface IupdateProps {
  companyId?: string
  payload?: any
  method?: any
  url?: string
}
export function useUpdateApi({
  companyId,
  contentType = true,
  method = METHOD_POST,
  onUpdate,
  onError,
  url,
}: IUseUpdateApiProps) {
  const [saving, setSaving] = useState(false)
  const { acquireToken } = useToken()

  const update = useCallback(
    async ({ companyId: _companyId, payload, method: _method, url: _url }: IupdateProps = {}) => {
      const rUrl = _url || url
      if (rUrl) {
        setSaving(true)
        const token = await acquireToken(_companyId || companyId)
        const headers: any = {
          Authorization: `Bearer ${token}`,
        }
        let body = payload || {}
        if (contentType) {
          headers['Content-Type'] = 'application/json'
          body = JSON.stringify(payload)
        }
        fetch(rUrl, {
          body,
          headers,
          method: _method || method,
        })
          .then(async rsp => {
            let jsonData = {}
            try {
              if (rsp.headers.get('Content-Type') === 'text/plain; charset=utf-8') {
                jsonData = await rsp.text()
              } else {
                jsonData = await rsp.json()
              }
            } catch (e) {
              jsonData = {}
            }

            return {
              data: jsonData,
              hasError: rsp.status >= 400,
              status: rsp.status,
              statusText: rsp.statusText,
            }
          })
          .then(res => {
            const { hasError } = res
            if (hasError) {
              if (onError) onError(res.data)
            } else if (onUpdate) onUpdate(res)
          })
          .catch(err => {
            if (onError) onError(err)
          })
          .finally(() => {
            setSaving(false)
          })
      }
    },
    [url, contentType, method, onUpdate, onError]
  )

  return { saving, update }
}
