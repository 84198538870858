import React from 'react'
import { IChildrenProps } from '@mondra/ui-components/@types'
import { Footer } from 'components/footer/Footer'

interface IAdditionalPageLayoutProps extends IChildrenProps {
  action?: React.ReactNode
  description: React.ReactNode
  status: string
  title: string
}

export function AdditionalPageLayout({
  action,
  description,
  status,
  title,
}: IAdditionalPageLayoutProps) {
  return (
    <div className="welcome-bg mx-auto flex w-full max-w-[1600px] flex-grow flex-col items-center pt-24">
      <div className="flex-grow">
        <div className="flex space-x-4 divide-x-2 divide-primary-600">
          <h2 className="text-2xl font-medium text-primary-500">{status}</h2>
          <div className="pb-4 pl-4">
            <div>
              <div className="text-xl font-medium leading-10 text-gray-800">{title}</div>
              <div className="mt-2 text-base font-normal leading-5 text-gray-600">
                {description}
              </div>
            </div>
            {action && <div className="mt-6">{action}</div>}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
