import React, { useState } from 'react'
import {
  ConfirmDialog,
  Button,
  Checkbox,
  Dialog,
  Icon,
  NavStepperCard,
  NavStepperContent,
  NavStepperFooter,
  Notification,
  ProgressBar,
  useNavStepper,
  useToastNotification,
} from '@mondra/ui-components'
import Lottie from 'lottie-react'
import { useUpdateApi } from 'hooks/useUpdateApi'
import { useInviteData } from 'hooks/useInviteData'
import { useAcceptInvite } from 'hooks/useAcceptInvite'
import { useCreateCompany } from 'hooks/useCreateCompany'
import { IApiError, ICompany, IEvent } from 'types'
import { parseApiError } from 'utils'
import { SectionTitle } from 'components/SectionTitle'
import { useCompany } from 'hooks/useCompany'
import { ERROR_TYPE, METHOD_PUT, TOAST_POSITION } from 'constants/'
import { resolveApiUrl } from 'utils/resolveUrl'
import { API_URLS } from 'constants/apiUrls'
import lottieSparkle from 'assets/lottie-sparkle.json'
import { CompanyExistDialog } from './CompanyExistDialog'

export function CreateCompany() {
  const [agreeAlert, setAgreeAlert] = useState(false)
  const [successDialog, setSuccessDialog] = useState(false)
  const [isNewCompany, setIsNewCompany] = useState<boolean>(false)
  const [companyErrorDialog, setCompanyErrorDialog] = useState(false)

  const { previousStep } = useNavStepper()
  const { showError } = useToastNotification(TOAST_POSITION)
  const { company, updateCompany } = useCompany()
  const {
    appData: { createAgree },
    contact,
    invite: { suppliers = [], token, retailerCompanyName },
    updateAppData,
  } = useInviteData()

  const onInviteAccepted = () => {
    setSuccessDialog(true)
  }

  const onError = (error: IApiError) => {
    showError(
      {
        ...parseApiError(error),
      },
      { duration: 5000, id: error.traceId! }
    )
  }

  const { acceptInvite, saving: accepting } = useAcceptInvite({
    onError,
    onSuccess: onInviteAccepted,
  })

  const onCompanyCreated = (newCompany: ICompany) => {
    setIsNewCompany(true)
    updateCompany(newCompany)
    const acceptApiUrl = resolveApiUrl(
      API_URLS.INVITE_ACCEPT,
      { companyId: newCompany?.id },
      { token }
    )
    acceptInvite({ companyId: newCompany?.id, url: acceptApiUrl })
  }

  const onCompanyCreateError = (error: IApiError) => {
    if (error?.code === ERROR_TYPE.COMPANY_DUPLICATE) {
      setCompanyErrorDialog(true)
    } else {
      onError(error)
      previousStep()
    }
  }

  const { createCompany, saving: creatingCompany } = useCreateCompany({
    company,
    contact,
    onError: onCompanyCreateError,
    onSuccess: onCompanyCreated,
  })

  const { update: updateUser, saving: updatingUser } = useUpdateApi({
    companyId: company?.id,
    method: METHOD_PUT,
    onError,
    onUpdate: () => acceptInvite({ companyId: company?.id }),
    url: API_URLS.USER,
  })

  const handleAgreeChecked = (event: IEvent) => updateAppData({ createAgree: event.target.checked })

  const closeAgreeDialog = () => {
    setAgreeAlert(false)
  }

  const handleCreateCompany = () => {
    if (!createAgree) {
      setAgreeAlert(true)
      return
    }
    if (company?.id) {
      const { firstName, lastName, phone, position } = contact
      if (position || phone) {
        updateUser({
          companyId: company.id,
          payload: {
            firstName,
            lastName,
            phone: phone || undefined,
            position: position || undefined,
          },
        })
      } else {
        acceptInvite({ companyId: company?.id })
      }
    } else {
      createCompany()
    }
  }

  const gotoDashboard = () => {
    if (process.env.REACT_APP_PROCESSOR_SAAS_URL) {
      window.location.href = process.env.REACT_APP_PROCESSOR_SAAS_URL
    }
  }

  const saving = accepting || creatingCompany || updatingUser
  const disabled = saving || successDialog

  return (
    <NavStepperCard className="max-h-screen-c-nav w-full max-w-[1400px]">
      <NavStepperContent className="flex flex-col gap-y-4 px-6 py-10">
        <div className="flex flex-grow flex-col-reverse gap-6 lg:flex-row">
          <div className="flex flex-1 flex-col">
            <SectionTitle className="mb-3" title="Your company" />
            <div className="mb-6 flex flex-col gap-y-4 text-coolGray-700">
              <div className="flex">
                <div className="w-full max-w-[240px] font-medium">Registered company name</div>
                <div className="font-normal">{company?.name}</div>
              </div>
              <div className="flex">
                <div className="w-full max-w-[240px] font-medium">Registration number</div>
                <div className="font-normal">{company?.registrationNumber}</div>
              </div>
            </div>
            <Notification type="warning" label="You are about to receive sensitive data">
              <div className="leading-6">
                Based on the aliases listed here, your retailer contact is giving you access to
                sensitive environmental and sales/purchase volume data on all the products you
                supply to them. It is your responsibility to check that the aliases you trade under
                are correct.
              </div>
            </Notification>
          </div>
          <div className="flex flex-1 flex-col">
            <SectionTitle className="mb-3" title="Your aliases" />
            <div className="flex flex-col gap-y-4">
              {suppliers.map(supplier => (
                <div key={supplier.id} className="text-sm font-medium text-primary">
                  {supplier.name}
                </div>
              ))}
            </div>
          </div>
        </div>

        <ConfirmDialog
          open={agreeAlert}
          onClose={closeAgreeDialog}
          title="Alert"
          primaryBtnText="Ok"
          onPrimaryBtnClick={closeAgreeDialog}
          hideSecondaryBtn
        >
          Please agree and accept the terms & conditions before proceeding.
        </ConfirmDialog>

        <Dialog className="rounded-lg md:max-w-[48rem]" open={successDialog} onClose={() => null}>
          <Dialog.Description className="relative flex items-center justify-center p-9">
            <Lottie
              className="absolute -top-20 left-28 z-0 opacity-50"
              loop
              animationData={lottieSparkle}
            />
            <div className="z-10 flex w-full flex-grow flex-col items-center justify-center text-center text-base text-primary">
              <div className="mb-12 text-3xl font-semibold">Congratulations</div>
              {isNewCompany ? (
                <div className="flex flex-col items-center justify-center space-y-6 text-center">
                  <div className="text-lg font-semibold">Your Mondra account has been created.</div>
                  <div className="flex items-center space-x-1">
                    <div>
                      <Icon type="checkmarkFilled" className="text-brand" />
                    </div>
                    <div className="line-through">Review submitted information</div>
                  </div>
                  <div className="flex items-center space-x-1">
                    <div>
                      <Icon type="checkmarkFilled" className="text-brand" />
                    </div>
                    <div className="line-through">Set up Mondra account</div>
                  </div>
                  <div className="flex w-full items-center space-x-1">
                    <div className="flex-1">
                      <ProgressBar
                        trackColor="p-1 bg-coolGray-100"
                        indicatorColor="bg-brand-enabled"
                        total={100}
                        value={80}
                        rounded
                        height="h-5"
                      />
                    </div>
                    <div className="flex-shrink-0 font-semibold text-brand">80%</div>
                  </div>
                  <div className="text-lg font-semibold">What&apos;s next?</div>
                  <div className="font-semibold">
                    We will contact you shortly to arrange an 1-2-1 introduction.
                  </div>
                  <div className="font-semibold">You can now close this window and relax.</div>
                </div>
              ) : (
                <div className="max-w-md">
                  <div>
                    Your <b>{retailerCompanyName}</b> data is being prepared and synchronised.
                  </div>
                  <div>This process happens overnight.</div>
                  <div className="py-6 font-semibold">
                    The data will be available to you tomorrow morning.
                  </div>
                  <div>
                    While you are waiting you can continue to use
                    <div>Mondra with your other datasets.</div>
                  </div>

                  <div className="z-10 mt-10 text-sm text-coolGray-200">
                    <Button variant="primary" onClick={gotoDashboard}>
                      Go to my dashboard
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Dialog.Description>
        </Dialog>
        <CompanyExistDialog
          contact={contact}
          open={companyErrorDialog}
          onClose={() => setCompanyErrorDialog(false)}
          token={token}
        />
      </NavStepperContent>
      <NavStepperFooter className="justify-between">
        <Checkbox
          label={
            <div>
              The information I have supplied is correct and I accept the{' '}
              <a
                href={process.env.REACT_APP_TERMS_CREATE_COMPANY}
                target="_blank"
                rel="noreferrer"
                className="text-primary-500"
              >
                Terms & Conditions
              </a>
            </div>
          }
          checked={createAgree}
          disabled={disabled}
          onChange={handleAgreeChecked}
        />
        <div className="flex gap-x-4">
          <Button
            iconType="arrowLeft"
            variant="secondary"
            disabled={disabled}
            onClick={previousStep}
          >
            Previous step
          </Button>
          <Button
            iconClass={saving ? 'animate-spin' : undefined}
            iconType={saving ? 'spinnerThird' : 'checkmark'}
            variant="primary"
            disabled={!createAgree || disabled}
            onClick={handleCreateCompany}
          >
            {saving ? 'Accepting...' : 'Accept invitation'}
          </Button>
        </div>
      </NavStepperFooter>
    </NavStepperCard>
  )
}
