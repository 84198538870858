import React, { useEffect, useMemo } from 'react'
import { INavSteps, NavStepper } from '@mondra/ui-components'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Footer } from 'components/footer/Footer'
import { Introduction } from 'components/introduction/Introduction'
import { ReviewInvite } from 'components/review-invite/ReviewInvite'
import { CompanyInformation } from 'components/company-information/CompanyInformation'
import { AboutInformation } from 'components/company-information/AboutInformation'
import { useInviteData } from 'hooks/useInviteData'
import { useCompany } from 'hooks/useCompany'
import { SkeletonLoader } from 'components/SkeletonLoader'
import { CreateCompany } from 'components/create-company/CreateCompany'

type StepNoLink = Omit<INavSteps, 'navLinkUrl'>

const stepIntro: StepNoLink = {
  element: <Introduction />,
  label: 'Introduction',
  path: 'intro',
}

const stepCompanyInfo: StepNoLink = {
  element: <CompanyInformation />,
  label: 'Your company',
  path: 'company-information',
}

const stepAboutInfo: StepNoLink = {
  element: <AboutInformation />,
  label: 'About you',
  path: 'about-information',
}

const stepReviewInvite: StepNoLink = {
  element: <ReviewInvite />,
  label: 'Review submitted info',
  path: 'review-invite',
}

const stepCreateCompany: StepNoLink = {
  element: <CreateCompany />,
  label: 'Confirm and create',
  path: 'create-company',
}

function Loader() {
  return (
    <div className="flex-grow">
      <div className="mb-12 flex w-full gap-x-0.5">
        <SkeletonLoader className="h-14 w-1/4" />
        <SkeletonLoader className="h-14 w-1/4" />
        <SkeletonLoader className="h-14 w-1/4" />
        <SkeletonLoader className="h-14 w-1/4" />
      </div>
      <div className="p-4 px-24">
        <SkeletonLoader className="h-6 w-64" />
        <SkeletonLoader className="mt-2 h-12 w-[700px]" />
        <SkeletonLoader className="mt-4 h-4 w-[500px]" />
      </div>
    </div>
  )
}

export function Onboarding() {
  const [queryParams] = useSearchParams()
  const navigate = useNavigate()
  const { loading } = useInviteData()
  const { isLoading: isCompaniesLoading, companies } = useCompany()

  const mappedSteps: INavSteps[] = useMemo(() => {
    const STEPS = [stepIntro, stepReviewInvite]

    if (companies.length === 0) {
      STEPS.push(stepCompanyInfo)
    }
    STEPS.push(stepAboutInfo)
    STEPS.push(stepCreateCompany)

    return STEPS.map(s => ({
      ...s,
      navLinkUrl: `./${s.path}?${queryParams.toString()}`,
    }))
  }, [queryParams, companies.length])

  // Switch to first step on page load
  // To prevent user to land on any future step by url
  useEffect(() => {
    navigate(mappedSteps[0].navLinkUrl)
  }, [mappedSteps])

  return (
    <div className="flex flex-grow flex-col">
      <div className="relative flex w-full min-w-[320px] flex-grow flex-col bg-secondary">
        {loading || isCompaniesLoading ? (
          <Loader />
        ) : (
          <NavStepper disableNavClick steps={mappedSteps} />
        )}
        <Footer />
      </div>
    </div>
  )
}
