import React from 'react'
import { Button } from '@mondra/ui-components'
import { useLocation } from 'react-router-dom'
import { GET_SUPPORT } from 'constants/'

export function RejectPage() {
  const { state } = useLocation()

  return (
    <div className="mx-auto mt-10 w-[600px] rounded border border-gray-300 bg-white">
      <div className=" px-6 py-10">
        <div className="mb-2 text-lg font-medium text-primary">Invitation rejected</div>
        <div className="text-sm leading-5 text-coolGray-700">
          You have rejected the invitation to join Mondra due to incorrect alias information being
          supplied.
          <br />
          <br />
          We will forward your feedback to {state?.retailerName || 'retailer'}, ask them to fix any
          issues and resubmit the invitation to you.
          <br />
          <br />
          If you have any queries please contact:{' '}
          <a
            href={GET_SUPPORT}
            target="_blank"
            rel="noopener noreferrer"
            className="font-medium text-brand"
          >
            support@mondra.com
          </a>
        </div>
      </div>
      <div className="flex justify-end border-t border-gray-300 p-6">
        <a href="https://www.mondra.com" rel="noopener noreferrer">
          <Button variant="secondary" size="md" rightIconType="arrowRight">
            Go to mondra.com
          </Button>
        </a>
      </div>
    </div>
  )
}
