import React, { createContext, useEffect, useMemo, useState, useCallback } from 'react'
import { useCompanies } from 'hooks/useCompanies'
import { ICompany } from 'types'

export interface ICompanyContextProps {
  companies: ICompany[]
  company?: ICompany
  companyId?: string
  isLoading: boolean
  setCompany: (company?: ICompany) => void
  updateCompany: (company: Partial<ICompany>) => void
}

const DEFAULT_COMPANY = {
  type: 'Processor',
}
export const defaultContextProps = {
  companies: [],
  company: undefined,
  companyId: undefined,
  isLoading: true,
  setCompany: () => {},
  updateCompany: () => {},
}

export const CompanyContext = createContext<ICompanyContextProps>(defaultContextProps)

interface ICompanyContextProviderProps {
  isAuthenticating: boolean
  children: React.ReactNode
}

export default function CompanyContextProvider({
  children,
  isAuthenticating,
}: ICompanyContextProviderProps) {
  const [company, setCompanyState] = useState<ICompany>()
  const { companies, isLoading } = useCompanies()

  const setCompany = useCallback((_company?: ICompany) => {
    const updatedValue = _company ? { ...DEFAULT_COMPANY, ..._company } : undefined

    setCompanyState(updatedValue)
  }, [])

  useEffect(() => {
    if (companies.length === 1) {
      setCompany({ ...DEFAULT_COMPANY, ...companies[0] })
    }
  }, [companies, setCompany])

  const updateCompany = useCallback((companyChanges: Partial<ICompany>) => {
    setCompanyState(prev => {
      return { ...prev, ...companyChanges }
    })
  }, [])

  const providerValue = useMemo(
    () => ({
      companies,
      company,
      companyId: company?.id,
      isLoading: isLoading || isAuthenticating,
      setCompany,
      updateCompany,
    }),
    [companies, company, isAuthenticating, isLoading, updateCompany, setCompany]
  )

  return <CompanyContext.Provider value={providerValue}>{children}</CompanyContext.Provider>
}
