import React, { useMemo } from 'react'
import { Avatar, MenuPopover, List, ConfirmDialog } from '@mondra/ui-components'
import { useAuth0 } from '@auth0/auth0-react'
import { useControls } from 'hooks/useControls'

const versionList = [
  {
    id: 'app',
    label: 'App Name',
    value: 'Mondra Onboarding',
  },
  {
    id: 'ui',
    label: 'UI',
    value: process.env.REACT_APP_UI_VERSION,
  },
  {
    id: 'api',
    label: 'API',
    value: process.env.REACT_APP_API_VERSION,
  },
]

export function UserAvatar() {
  const { isOpened, open, close } = useControls()
  const { logout, user } = useAuth0()
  const options = useMemo(() => {
    return [
      { id: 'about', onClick: open, value: 'About' },
      {
        id: 'logout',
        onClick: () => {
          logout({ logoutParams: { returnTo: window.location.origin } })
        },
        value: 'Logout',
      },
    ]
  }, [logout, open])

  const userName = useMemo(() => {
    if (user?.given_name && user?.family_name) {
      return `${user?.given_name} ${user?.family_name}`
    }
    return `${user?.given_name || user?.family_name || user?.email || 'NA'}`
  }, [user?.given_name, user?.family_name, user?.email])

  return (
    <div className="text-sm font-medium">
      <MenuPopover
        className=""
        triggerButtonClass="h-10 w-10 hover:bg-graySecondary-900"
        menuItems={options}
        trigger={
          <div className="flex items-center">
            <Avatar
              name={userName}
              size="xs"
              bgColor="bg-cyan-600"
              textColor="text-white"
              singleChar
            />
          </div>
        }
      />

      <ConfirmDialog
        open={isOpened}
        onClose={close}
        title="About this application"
        primaryBtnText="Ok"
        onPrimaryBtnClick={close}
        hideSecondaryBtn
      >
        <List className="my-4 rounded border border-gray-300" items={versionList} />
      </ConfirmDialog>
    </div>
  )
}
