import React, { useMemo } from 'react'
import { ConfirmDialog, Link } from '@mondra/ui-components'
import { parseTemplate } from 'utils'
import { IContact } from 'types'
import { SUPPORT_MAIL } from 'constants/'

const NL_BREAK = '%0D%0A'
const emailSubject = 'Onboarding Error: Existing Company Registration'

const emailContent = `Dear Mondra Support Team,${NL_BREAK}
${NL_BREAK}
Unfortunately, I have encountered an issue when trying to register my company. I received a notification that the company registration number I provided already exists in your system.${NL_BREAK}
${NL_BREAK}
Could you help me sort this out? I understand I will hear back from you shortly.${NL_BREAK}
${NL_BREAK}
Invite Token: {{TOKEN}}${NL_BREAK}
${NL_BREAK}
Thanks!${NL_BREAK}
{{USERNAME}}`

interface ICompanyExistDialogProps {
  contact: IContact
  open: boolean
  onClose: () => void
  token?: string
}

export function CompanyExistDialog({ open, onClose, token, contact }: ICompanyExistDialogProps) {
  const emailTemplate = useMemo(() => {
    return parseTemplate(emailContent, { TOKEN: token!, USERNAME: contact.firstName! })
  }, [token, contact])

  return (
    <ConfirmDialog
      hideFooter
      variant="warn"
      className="!px-4 !pb-6 !pt-4"
      title="Your company registration number already exists"
      open={open}
      onClose={onClose}
    >
      <Link as="a" to={`mailto:${SUPPORT_MAIL}?subject=${emailSubject}&body=${emailTemplate}`}>
        Click here
      </Link>
      &nbsp; to log a ticket to our support team, and we will get back to you
    </ConfirmDialog>
  )
}
