import React, { useLayoutEffect } from 'react'
import { Button, Tooltip, useToastNotification } from '@mondra/ui-components'
import { TOAST_POSITION } from 'constants/'
import { ZOHO_USER_TOKEN_ERROR } from 'constants/errorMessages'
import { useZohoAsapUserToken } from 'hooks/useZohoAsapUserToken'

type SuccessCallback = (token: string) => void
type FailureCallback = (error: unknown) => void
type InvokeOpenActionType = (action: string) => void
type InvokeLoginType = (
  action: string,
  callback: (successCallback: SuccessCallback, failureCallback: FailureCallback) => void
) => void
type InvokeType = InvokeOpenActionType | InvokeLoginType

declare global {
  interface Window {
    ZohoDeskAsap: {
      invoke: InvokeType
    }
    ZohoHCAsapSettings: {}
    ZohoDeskAsapReady: (a: unknown) => void
    ZohoDeskAsap__asyncalls: unknown
    ZohoDeskAsapReadyStatus: unknown
  }
}

window.ZohoHCAsapSettings = {
  hideLauncherIcon: true,
}

export function Help() {
  const { showError } = useToastNotification(TOAST_POSITION)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUpdate = (data: any) => {
    const loginAction = window.ZohoDeskAsap?.invoke as InvokeLoginType
    try {
      // eslint-disable-next-line func-names
      loginAction('login', async function (successCallback, errorCallback) {
        if (data.data) {
          successCallback(data.data)
        } else {
          errorCallback('Error while fetching token')
        }
      })
    } catch (error) {
      showError({
        label: ZOHO_USER_TOKEN_ERROR.AUTHENTICATION,
      })
    }
  }

  const { fetchZohoUserToken } = useZohoAsapUserToken({ onUpdate })

  useLayoutEffect(() => {
    const zohoScript = document.querySelector('[aria-label="zoho-portal"]')

    if (!zohoScript) {
      const d = document
      const s = d.createElement('script')
      s.type = 'text/javascript'
      s.defer = true
      s.ariaLabel = 'zoho-portal'
      s.id = 'zohodeskasapscript'
      s.src = process.env.REACT_APP_ZOHO_CHAT_URL as string
      d.getElementsByTagName('head')[0].appendChild(s)
      // eslint-disable-next-line func-names
      window.ZohoDeskAsapReady = function (a: unknown) {
        // eslint-disable-next-line no-multi-assign, @typescript-eslint/no-explicit-any
        const e = (window.ZohoDeskAsap__asyncalls || []) as any
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        window.ZohoDeskAsapReadyStatus
          ? (a && e.push(s),
            e.forEach((et: () => unknown) => et && et()),
            (window.ZohoDeskAsap__asyncalls = null))
          : a && e.push(a)
      }
    }
  }, [])

  const handleOpen = async () => {
    const invokeAction = window.ZohoDeskAsap?.invoke as InvokeOpenActionType
    if (invokeAction) {
      invokeAction('logout')
    }
    fetchZohoUserToken()
    if (invokeAction) {
      invokeAction('open')
    }
  }

  return (
    <Tooltip interactive content="How can we help?">
      <Button
        variant="white"
        iconType="statusHelp"
        isOnlyIcon
        size="xl"
        onClick={handleOpen}
        className="p-4.5"
      />
    </Tooltip>
  )
}
