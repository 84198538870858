import React from 'react'

interface IErrorBlockProps {
  errors: { [key: string]: string[] }
  message: string
  traceId: string
}

export function ErrorBlock({ errors, message, traceId }: IErrorBlockProps) {
  const errorList = Object.keys(errors)

  return (
    <div className="mt-2 text-sm font-normal leading-5 text-secondary">
      <div>{message}</div>
      {errorList.length > 0 && (
        <ul className="mb-0 ml-4 mt-2 list-disc">
          {errorList.map(e => (
            <li key={e}>{`${e}: ${errors[e].join(', ')}`}</li>
          ))}
        </ul>
      )}
      {traceId && <div className="mt-4 text-xs text-secondary">TraceId - {traceId}</div>}
    </div>
  )
}
