import React, { useCallback, useMemo } from 'react'
import { Autocomplete, Input } from '@mondra/ui-components'
import find from 'lodash/fp/find'
import FormRow, { FormLabel } from 'components/row/FormRow'
import useCountries from 'hooks/useCountries'
import { useCompany } from 'hooks/useCompany'

export function CompanyAddress() {
  const { company, updateCompany } = useCompany()
  const { countries, loading: countryLoading } = useCountries()

  const handleLeEntityChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target
      updateCompany({ [name]: value })
    },
    [updateCompany]
  )

  const handleCountryChange = useCallback(
    ({ selectedItem }) => {
      updateCompany({ country: selectedItem?.label })
    },
    [updateCompany]
  )

  const selectedCountry = useMemo(() => {
    return find(c => c.value === company?.country, countries)
  }, [countries, company?.country])

  return (
    <>
      <FormRow>
        <FormLabel>Address</FormLabel>
        <Input
          autoComplete="new-street"
          required
          name="street"
          type="text"
          onChange={handleLeEntityChange}
          value={company?.street || ''}
        />
      </FormRow>
      <FormRow>
        <FormLabel>City</FormLabel>
        <Input
          autoComplete="new-city"
          required
          name="city"
          type="text"
          onChange={handleLeEntityChange}
          value={company?.city || ''}
        />
      </FormRow>
      <FormRow>
        <FormLabel className="!flex justify-between leading-6">
          <div>County / State</div>
          <div>Optional</div>
        </FormLabel>
        <Input
          autoComplete="new-state"
          required
          name="state"
          type="text"
          onChange={handleLeEntityChange}
          value={company?.state || ''}
        />
      </FormRow>
      <FormRow className="mb-4 flex-row flex-wrap gap-x-4 gap-y-4">
        <div className="flex-grow basis-0">
          <FormLabel>Country</FormLabel>
          <Autocomplete
            containerClass="flex flex-col"
            placeholder="Select country"
            selected={selectedCountry}
            disabled={countryLoading}
            noOptionText="Country does not exist"
            onChange={handleCountryChange}
            options={countries}
          />
        </div>
        <div className="flex-grow basis-0">
          <FormLabel>Postcode</FormLabel>
          <Input
            required
            autoComplete="new-postcode"
            name="postCode"
            type="text"
            onChange={handleLeEntityChange}
            value={company?.postCode || ''}
          />
        </div>
      </FormRow>
    </>
  )
}
