import React from 'react'
import { IChildrenProps, IClassNameProps } from '@mondra/ui-components/@types'
import { Label } from '@mondra/ui-components'
import classNames from 'classnames'

interface IFormRowProps extends React.ComponentPropsWithoutRef<'div'> {
  showBottomBorder?: boolean
}
export default function FormRow({
  className = 'flex-col',
  children,
  showBottomBorder,
}: IFormRowProps) {
  return (
    <div
      className={classNames(
        'items-left flex w-full gap-1',
        showBottomBorder && 'border-b border-gray-200',
        className
      )}
    >
      {children}
    </div>
  )
}

export function FormLabel({ children, className }: IChildrenProps & IClassNameProps) {
  return (
    <Label
      containerClass="whitespace-nowrap"
      className={classNames('text-xs font-normal !text-secondary', className)}
    >
      {children}
    </Label>
  )
}
