import React, { useCallback, useMemo, useState } from 'react'
import {
  Button,
  Checkbox,
  ConfirmDialog,
  NavStepperCard,
  NavStepperContent,
  NavStepperFooter,
  Textarea,
  useNavStepper,
  useToastNotification,
} from '@mondra/ui-components'
import { useNavigate } from 'react-router-dom'
import isEmpty from 'lodash/fp/isEmpty'
import pluralize from 'pluralize'
import { useInviteData } from 'hooks/useInviteData'
import { useUpdateApi } from 'hooks/useUpdateApi'
import { IApiError, IEvent, ISupplier } from 'types'
import { ROUTE_URLS } from 'constants/routeUrls'
import { METHOD_POST, TOAST_POSITION } from 'constants/'
import { API_URLS } from 'constants/apiUrls'
import { resolveApiUrl } from 'utils/resolveUrl'
import { parseApiError } from 'utils'
import { SectionTitle } from 'components/SectionTitle'
import { SupplierListItem } from './SupplierListItem'

export function ReviewInvite() {
  const navigate = useNavigate()
  const { nextStep, setActiveIndex } = useNavStepper()
  const { invite, reset } = useInviteData()
  const [suppliers, setSuppliers] = useState<ISupplier[]>(invite.suppliers || [])
  const [isRejectCheck, setRejectCheck] = useState(false)
  const [rejectionReason, setRejectionReason] = useState<string>('')
  const [rejectWarning, setRejectWarning] = useState(false)
  const { showError } = useToastNotification(TOAST_POSITION)

  const onError = (error: IApiError) => {
    showError(
      {
        ...parseApiError(error),
      },
      { duration: 5000, id: error.traceId! }
    )
  }

  const onUpdate = useCallback(() => {
    reset()
    navigate(ROUTE_URLS.REJECT, { state: { retailerName: invite.retailerCompanyName } })
  }, [invite, reset])

  const { update: reject, saving } = useUpdateApi({
    method: METHOD_POST,
    onError,
    onUpdate,
    url: resolveApiUrl(API_URLS.INVITE_REJECT, {}, { token: invite.token }),
  })

  const toggleSupplierReject = (index: number) => () => {
    setSuppliers(spls =>
      spls.map((s, i) => {
        if (i === index) {
          return {
            ...s,
            isReject: !s.isReject,
            rejectionReason: '',
          }
        }
        return s
      })
    )
  }

  const handleSupplierReasonChange = (index: number) => (event: IEvent) => {
    setSuppliers(spls =>
      spls.map((s, i) => {
        if (i === index) {
          return {
            ...s,
            rejectionReason: event.target.value,
          }
        }
        return s
      })
    )
  }

  const handleMainReasonChange = event => {
    setRejectionReason(event.target.value)
  }

  const showRejectWarning = () => {
    setRejectWarning(true)
  }

  const closeRejectWarning = () => {
    setRejectWarning(false)
  }

  const handleReject = () => {
    closeRejectWarning()

    if (!saving) {
      const rejectedSuppliers = suppliers.filter(s => !isEmpty(s.rejectionReason))
      reject({
        payload: {
          rejectionReason,
          suppliers: rejectedSuppliers,
        },
      })
    }
  }

  const { isRejectable, showReject } = useMemo(() => {
    const isMainRejected = isRejectCheck && !isEmpty(rejectionReason.trim())

    const rejectableSuppliers = suppliers.filter(s => s.isReject)
    const hasMsg = rejectableSuppliers.every(s => !isEmpty(s.rejectionReason))

    return {
      isRejectable:
        (rejectableSuppliers.length > 0 && hasMsg && !isRejectCheck) || (isMainRejected && hasMsg),
      showReject: isRejectCheck || rejectableSuppliers.length > 0,
    }
  }, [isRejectCheck, rejectionReason, suppliers])

  const handleRejectCheckChange = (event: IEvent) => {
    setRejectCheck(event.target.checked)
    setRejectionReason('')
  }

  return (
    <NavStepperCard className="max-h-screen-c-nav max-w-[680px]">
      <NavStepperContent className="flex h-10 flex-col gap-y-6 px-6 py-10">
        <SectionTitle
          title="Review your aliases"
          description={`${invite.retailerCompanyName} told us that you supply to them through the aliases listed here. Please check through this list and reject any brands that are not yours. If any are incorrect we will ask ${invite.retailerCompanyName} to correct the errors and resubmit the invitation.`}
        />

        <div className="flex w-full flex-grow flex-col gap-y-4 text-sm text-primary">
          <div className="flex flex-grow flex-col border border-gray-300">
            <div className="border-b border-gray-300 p-4 font-medium">
              {pluralize('alias', suppliers.length, true)} submitted
            </div>
            <ul className="flex-auto divide-y">
              {suppliers?.map((s: ISupplier, i: number) => (
                <SupplierListItem
                  key={s.id}
                  supplier={s}
                  onRejectMsgChange={handleSupplierReasonChange(i)}
                  onRejectToggle={toggleSupplierReject(i)}
                />
              ))}
            </ul>
          </div>

          <div>
            <Checkbox
              label="Reject all aliases listed here"
              checked={isRejectCheck}
              onChange={handleRejectCheckChange}
            />
            {isRejectCheck && (
              <Textarea
                className="mt-2 !h-14 !max-h-14 w-full"
                placeholder="Enter rejection reason"
                rows={3}
                invalid={isEmpty(rejectionReason)}
                value={rejectionReason}
                onChange={handleMainReasonChange}
              />
            )}
          </div>
        </div>
        <ConfirmDialog
          variant="danger"
          title="Alert"
          primaryBtnText="Confirm"
          open={rejectWarning}
          onClose={closeRejectWarning}
          onPrimaryBtnClick={handleReject}
        >
          Are you sure to reject this invitation?
        </ConfirmDialog>
      </NavStepperContent>
      <NavStepperFooter>
        <Button iconType="arrowLeft" variant="secondary" onClick={() => setActiveIndex(0)}>
          Previous step
        </Button>
        {showReject ? (
          <Button
            variant="danger"
            onClick={showRejectWarning}
            rightIconType={saving ? 'spinnerThird' : 'close'}
            iconClass={saving ? 'animate-spin' : undefined}
            disabled={!isRejectable || saving}
          >
            Reject invitation
          </Button>
        ) : (
          <Button variant="primary" disabled={saving} onClick={nextStep} rightIconType="arrowRight">
            Next step
          </Button>
        )}
      </NavStepperFooter>
    </NavStepperCard>
  )
}
